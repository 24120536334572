* {
  padding: 0;
  margin: 0;
}

/* Inter-Regular */
@font-face {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 200;
  src: url("./assets//fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 400;
  src: url("./assets//fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-SemiBold";
  font-style: normal;
  font-weight: 400;
  src: url("./assets//fonts/Inter-SemiBold.ttf");
}
